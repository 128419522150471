<template>
  <div class="dealRequest">
    <!-- 头部导航栏 -->
    <div class="card-header">
      <i class="el-icon-edit"></i>
      <span>Deal Request</span>
    </div>
    <!-- 底部商品栏 -->
    <div class="requestList">
      <!-- 商品栏 -->
      <div class="requestBox"
           v-for="(item, index) in reqList"
           :key='index'>
        <!-- 商品图片 -->
        <div class="reqImg">
          <img :src="item.product_image_url"
               alt="" />
        </div>
        <!-- 商品名称和时间 -->
        <div class="reqGoods">
          <div class="reqName">
            <span :class="item.site_class"></span>
            <span @click="dealGood(item)">{{item.product_title}}</span>
          </div>
          <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>
          <div class="reqTime"><b>Creation Time</b> : {{item.create_date | formatDate}}</div>
        </div>
        <!-- 商品卖方状态等 -->
        <div class="reqCuntent">
          <div class="reqState"><b>Status</b> : {{item.status}}</div>
          <div class="reqCoupon"><b>Coupon Code</b> : {{item.coupon_code}}</div>
          <div class="reqCoupon"><b>OrderNumber</b> : {{item.order_number}}</div>
        </div>
        <!-- 删除或购买商品 -->
        <div class="reqBtn">
          <el-button type="primary"
                     @click="getClick(item)"
                     class="success"
                     icon="el-icon-success"
                     v-if="item.show">I'm ordered</el-button>
          <el-button type="primary"
                     @click="errorTips(item)"
                     class="error"
                     icon="el-icon-error"
                     v-if="item.show">Cancel</el-button>
          <!-- <el-button type="primary" @click="$router.push('./reviewChat')" class="round" icon="el-icon-chat-dot-round"
            >Chat</el-button
          > -->
          <el-link :href="item.amazon_url"
                   class="share"
                   target="_blank"
                   icon="el-icon-share"
                   :underline="false">Amazon URL</el-link>
        </div>
        <!-- 消息提醒 -->
        <!-- <span class="information">1</span> -->
      </div>
      <!-- 分页 -->
      <div class="block"
           v-if="reqListBool">
        <div class="blockDiv">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="currentPagesize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
        </div>
      </div>
      <!-- 弹出层 -->
      <el-dialog title="Order information"
                  :visible.sync="dialogFormVisible"
                  :append-to-body="true">
        <el-form :model="itemGood">
          <el-form-item label="Trade name:"
                        label-width="100px">
            <span style="overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;display:block;width:400px">{{itemGood.product_title}}</span>
          </el-form-item>
          <el-form-item label="Creation time:"
                        label-width="100px">
            <span>{{itemGood.create_date | formatDate}}</span>
          </el-form-item>
          <el-form-item label="Amazon order number:"
                        label-width="100px">
            <el-input v-model="form.order"
                      autocomplete="off"
                      placeholder="Please enter Amazon order number"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer"
              class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary"
                      @click="successTips()" :loading="$store.state.loadingBool">confirm</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { formatDate } from '../../utils/data.js'
import {
  dealList, // 折扣
  deleteDeal, // 折扣-删除申请折扣
  dealOrderNumber // 折扣-提交亚马逊订单
} from '../../api/buyerAfter'
export default {
  data () {
    return {
      currentPage: 1,
      currentPagesize: 10,
      currentTotal: 0,
      show: true,
      // 商品栏
      reqList: [],
      reqListBool: false,
      // 弹出层控制
      dialogFormVisible: false,
      // 商品表单
      form: {
        order: ''
      },
      itemGood: {}
    }
  },
  filters: {
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.getdealList()
    this.$store.commit('getLoading', false)
  },
  methods: {
    // 折扣列表
    getdealList () {
      // 折扣
      dealList({
        page: 1,
        size: this.currentPagesize
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.content
          this.currentTotal = result.data.data.total_elements
          // console.log(this.reqList)
          this.reqList.map(v => {
            // (v.product_image_url)
            v.product_image_url = JSON.parse(v.product_image_url)[0]
          })
          // 是否显示分页
          if (this.reqList.length > 0) {
            this.reqListBool = true
          } else {
            this.reqListBool = false
          }
          this.reqList.map((v, index) => {
            if (v.status === 'cancelled' || v.status === 'ordered') {
              v.show = false
            } else {
              v.show = true
            }
          })
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    // 弹框
    getClick (item) {
      this.dialogFormVisible = true
      // console.log(item)
      this.itemGood = JSON.parse(JSON.stringify(item))
    },
    // 确认提示
    successTips (item) {
      this.$store.commit('getLoading', true)
      // console.log(this.itemGood)
      // 折扣-提交亚马逊订单
      dealOrderNumber({
        id: this.itemGood.id,
        orderNumber: this.form.order
      }).then(result => {
        if (result.data.code === 200) {
          this.$store.commit('getLoading', false)
          this.$message.success(result.data.message)
          this.dialogFormVisible = false
          this.getdealList()
          // setTimeout(() => {
          //   this.$router.go(0)
          // }, 500)
        } else {
          this.$store.commit('getLoading', false)
        }
        // ('result ==>', result)
      }).catch(err => {
        return err
      })
    },
    // 删除提示
    errorTips (item) {
      // (item.id)
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        message: h('p', null, [
          h('span', null, 'Are you sure to cancel this request? ')
          // h('i', { style: 'color: teal' }, 'VNode'),
        ]),
        showClose: false,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
            // ('aaaa')
            instance.confirmButtonLoading = false
          } else {
            // ('bbbb')
            done()
          }
        }
      })
        .then((result) => {
          // ('result ==>', result)
          this.$msgbox({
            title: 'news',
            message: h('p', null, [h('span', null, 'Cancle success ')]),
            showClose: false,
            showCancelButton: false,
            confirmButtonText: 'confirm',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                // 折扣-删除申请折扣
                deleteDeal({
                  id: item.id
                }).then(result => {
                  if (result.data.code === 200) {
                    this.$message.success(result.data.message)
                    setTimeout(() => {
                      this.$router.go(0)
                    }, 500)
                  }
                  // ('result ==>', result)
                }).catch(err => {
                  return err
                })
                done()
                instance.confirmButtonLoading = false
              }
            }
          })
        })
        .catch((err) => {
          return err
        })
    },
    // 分页
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.currentPagesize = val
      // 折扣
      dealList({
        page: this.currentPage,
        size: val
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.content
          this.currentTotal = result.data.data.total_elements
          this.reqList.map((v, index) => {
            v.product_image_url = JSON.parse(v.product_image_url)[0]
            if (v.status === 'cancelled' || v.status === 'ordered') {
              v.show = false
            } else {
              v.show = true
            }
          })
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    handleCurrentChange (val) {
      // (`当前页: ${val}`)
      // 折扣
      dealList({
        page: val,
        size: this.currentPagesize
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.content
          this.currentTotal = result.data.data.total_elements
          this.reqList.map((v, index) => {
            v.product_image_url = JSON.parse(v.product_image_url)[0]
            if (v.status === 'cancelled' || v.status === 'ordered') {
              v.show = false
            } else {
              v.show = true
            }
          })
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    // 进入详情页
    dealGood (item) {
      this.$router.push({ path: '/F_dealDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    }
  }
}
</script>

<style lang="less" scoped>
.dealRequest {
  width: 100%;
  height: 100%;
  .card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      margin-left: 10px;
      line-height: 45px;
    }
  }
  //底部商品栏
  .requestList {
    background-color: white;
    padding: 20px;
    // 商品栏
    .requestBox {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      // 商品图片
      .reqImg {
        img {
          max-height: 100px;
          max-width: 100px;
        }
      }
      // 商品名称和时间
      .reqGoods {
        width: 30%;
        // margin-right: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100px;
        font-size: 14px;
        .reqName {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            line-height: 2;
            &:nth-child(2) {
              width: calc(100% - 24px);
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 10px;
              cursor: pointer;
              color: #20a8d8;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .reqTime {
          line-height: 2;
          text-align: left;
        }
        .reqSell {
          width: 95%;
          line-height: 2;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      // 商品卖方状态等
      .reqCuntent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        min-height: 100px;
        font-size: 14px;
        div {
          line-height: 2;
        }
      }
      // 删除或购买商品
      .reqBtn {
        width: 28%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /deep/ .el-button {
          padding: 5px 10px;
          /deep/ i {
            font-size: 16px;
          }
        }
        /deep/ .el-button--primary.is-plain {
          color: white;
        }
        .success {
          background-color: #4dbd74;
          border-color: #4dbd74;
        }
        .error {
          margin-left: 10px;
          background-color: #f86c6b;
          border-color: #f86c6b;
        }
        .round {
          background-color: #20a8d8;
          border-color: #20a8d8;
        }
        .share {
          display: block;
          margin-left: 10px;
          font-size: 14px;
          padding: 7px 10px;
          border-radius: 5px;
          background-color: #2f353a;
          border-color: #2f353a;
          color: white;
        }
      }
      // 消息提醒
      .information {
        position: absolute;
        right: -10px;
        top: -10px;
        // display: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        background-color: tomato;
        text-align: center;
        line-height: 20px;
        font-weight: bolder;
      }
    }
  }
}
/deep/ .el-dialog {
  box-shadow: none;
}
/deep/ .el-form-item {
  display: flex;
  justify-content: flex-start;
}
/deep/ .el-form-item__label {
  display: inline;
  min-width: 160px;
}
/deep/ .el-form-item__content {
  margin: 0 !important;
}
</style>
